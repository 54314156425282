import { Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { secondaryColor, whiteColor } from 'library/Colors';
import { QuemSomosHashName } from 'library/Routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: secondaryColor,
        width: '100%',
        padding: theme.spacing(0, 0, 3, 0),
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      title: {
        fontSize: `${theme.spacing(3)}px`,
        fontWeight: 700,
        textAlign: 'center',
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
        },
      },
      text: {
        color: whiteColor,
        fontSize: `21px`,
        textAlign: 'center',
        marginTop: theme.spacing(3),
        maxWidth: '760px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        }
      },
    }),
);

interface Props {
  className?: string;
}

const QuemSomos = ({className}: Props) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Element
      name={QuemSomosHashName}
      className={[
        classes.root,
        className || ''
      ].join(' ')}
    >
      <Container maxWidth="md">
        <div className={classes.content}>
          <Typography component="h2" color="primary" className={classes.title}>
            {t(`Home.QuemSomos.title`)}
          </Typography>
          <Typography component="p" className={classes.text}>
            <span dangerouslySetInnerHTML={{__html: t(`Home.QuemSomos.text`)}} />
          </Typography>
        </div>
      </Container>
    </Element>
  )
}

export default QuemSomos;