import React from 'react';
import { IconUIComponentProps, useIconStyle } from './Icon';

const MessageIcon = ({ color, size, style, className, noFill }: IconUIComponentProps) => {
  const styles = useIconStyle({ noFill, size, color });
  return (
    <svg
      className={[styles.icon, className].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.5 4L16.5 4C17.3438 4 18 4.6875 18 5.5L18 14.5C18 15.3438 17.3437 16 16.5 16L3.5 16C2.6875 16 2 15.3437 2 14.5L2 5.5C2 4.6875 2.6875 4 3.5 4ZM3.5 5.5L3.5 6.78125C4.21875 7.375 5.34375 8.25 7.71875 10.125C8.25 10.5312 9.28125 11.5312 10 11.5C10.75 11.5312 11.7812 10.5312 12.3125 10.125C14.6875 8.25 15.8125 7.375 16.5 6.78125L16.5 5.5L3.5 5.5ZM16.5 14.5L16.5 8.71875C15.8125 9.28125 14.7812 10.0937 13.25 11.3125C12.5625 11.8437 11.3437 13.0312 10 13C8.6875 13.0312 7.5 11.8437 6.78125 11.3125C5.25 10.0937 4.21875 9.28125 3.5 8.71875L3.5 14.5L16.5 14.5Z" />
    </svg>
  );
}

export default MessageIcon;