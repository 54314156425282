import { IRoute } from 'interfaces/IRoute';
import Home from 'pages/Home';
import { Route } from 'react-router-dom';

let routes: IRoute[] = [];

export const basePath: string = '/';

export const HomeRoutePath: string = basePath;
export const HomeRoute: IRoute = {
  path: HomeRoutePath,
  component: Home,
  exact: true,
};
routes.push(HomeRoute);

export const HomeHashName: string = 'home';
export const QuemSomosHashName: string = 'quem-somos';
export const QuemSomosRoutePath: string = `${HomeRoutePath}#${QuemSomosHashName}`;
export const AreasAtuacaoHashName: string = 'areas-atuacao';
export const AreasAtuacaoRoutePath: string = `${HomeRoutePath}#${AreasAtuacaoHashName}`;
export const CarreirasHashName: string = 'carreiras';
export const CarreirasRoutePath: string = `${HomeRoutePath}#${CarreirasHashName}`;
export const ContatoHashName: string = 'contato';
export const ContatoRoutePath: string = `${HomeRoutePath}#${ContatoHashName}`;
export const AddressUrl: string = 'https://www.google.com/maps/place/Tv.+S%C3%A3o+Jos%C3%A9,+445+-+Navegantes,+Porto+Alegre+-+RS,+90240-200/@-29.9964167,-51.2035373,17z/data=!3m1!4b1!4m5!3m4!1s0x9519798b1671aa71:0x2a0b0b9e122443ee!8m2!3d-29.9964214!4d-51.2013486';

export function getRouteComponent(route: IRoute, key: number) {
  const args = {
    path: route.path || undefined,
    component: route.component,
    exact: route.exact
  };
  return <Route {...args} key={key} />;
}

export function getAllRouteComponents() {
  return routes.map((route, index) => getRouteComponent(route, index));
}