import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {primaryColor, secondaryColor} from 'library/Colors'

export interface IconUIComponentProps {
  className?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'error' | 'disabled' | string;
  size?: 'small' | 'medium' | number | string | undefined;
  style?: React.CSSProperties;
  noFill?: boolean;
}

interface HookProps {
  color?: 'inherit' | 'primary' | 'secondary' | 'error' | 'disabled' | string;
  noFill?: boolean;
  size?: number | string | undefined;
}

const getColor = (
  color: 'inherit' | 'primary' | 'secondary' | 'error' | 'disabled' | string = 'currentColor',
  noFill: boolean = false,
) => {
  if (noFill) {
    return 'none';
  }
  switch (color) {
    case 'primary':
      return primaryColor;
    case 'secondary':
      return secondaryColor;
    case 'currentColor':
    default:
      return color;
  }
};

const getSize = (size?: 'small' | 'medium' | number | string | undefined) => {
  switch (size) {
    case 'small':
      return '16px';
    case 'medium':
      return '24px';
    default:
      return size || '1.5em';
  }
};

export const useIconStyle = makeStyles<Theme, HookProps>(
  (theme) =>
    createStyles({
      icon: {
        fill: (props) => getColor(props.color, props.noFill),
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: (props) => getSize(props.size),
        flexShrink: 0,
        userSelect: 'none',
      },
    }),
  {
    classNamePrefix: 'icon-ui-component',
  },
);
