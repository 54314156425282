import { AppBar, Button, Container, createStyles, IconButton, makeStyles, Theme, Toolbar } from "@material-ui/core";
import Brand from "components/Brand";
import MenuIcon from "components/Icons/MenuIcon";
import LayoutContext from "components/Layout/LayoutContext";
import PagesContext from "components/Pages/PagesContext";
import { secondaryColor, whiteColor } from "library/Colors";
import { AreasAtuacaoHashName, CarreirasHashName, ContatoHashName, HomeRoutePath, QuemSomosHashName } from "library/Routes";
import { Fragment, useContext, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import MenuItem from './MenuItem';

const useStyles = (topBarHeight: number) => makeStyles(
  (theme: Theme) =>
    createStyles({
      appBar: {
        backgroundColor: secondaryColor + ' !important',
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3) !important',
        transition: theme.transitions.create(['background-color', 'box-shadow', 'min-height', 'max-height'], {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeInOut,
        }),
        color: whiteColor + ' !important',
        height: '100% !important',
        minHeight: topBarHeight + 'px !important',
        maxHeight: topBarHeight + 'px !important',
        overflow: 'hidden',
      },
      transparentAppBar: {
        backgroundColor: `transparent !important`,
        boxShadow: 'none !important',
      },
      appBarRelative: {
        display: 'block',
        transition: theme.transitions.create(['min-height', 'max-height'], {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeInOut,
        }),
        minHeight: topBarHeight + 'px !important',
        maxHeight: topBarHeight + 'px !important',
      },
      topBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100% !important',
      },
      topBarContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > *:last-child': {
          marginRight: theme.spacing(-1),
        },
        '@media (max-width: 959px)': {
          padding: 0
        }
      },
      seloCentralAtendimento: {
        marginLeft: theme.spacing(1),
      },
      brandButton: {
        color: 'initial !important',
      },
      brand: {
        marginLeft: theme.spacing(-1),
        fontSize: '196px !important',
        [theme.breakpoints.down('sm')]: {
          fontSize: '90px !important',
        },
      },
      menuButtonContainer: {
        position: 'relative',
        width: '0',
      },
      menuButton: {
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        color: whiteColor,
        marginRight: '-6px',
      },
      menuIcon: {
        fontSize: '30px',
      },
    }),
);

const TopBar = () => {
  const {t} = useTranslation();
  const {isMdSize, setLeftMenuOpened, relativeTopBarHidden, topBarHeight, scrolled} = useContext(LayoutContext);
  const classes = useStyles(topBarHeight)();
  const {toHome, toQuemSomos, toAreasAtuacao, toCarreiras, toContato} = useContext(PagesContext);

  const backgroundIsTransparent = useMemo<boolean>(() => {
    return relativeTopBarHidden && !scrolled;
  }, [relativeTopBarHidden, scrolled])

  return (
    <Fragment>
      <AppBar color="transparent" position="fixed" className={[
        classes.appBar,
        backgroundIsTransparent ? classes.transparentAppBar : ''
        ].join(' ')}>
        <Toolbar className={classes.topBar}>
          <Container className={classes.topBarContainer} maxWidth="md">
            <Button
              className={classes.brandButton}
              onClick={() => toHome()}
            >
              <Brand className={classes.brand} />
            </Button>
            {
              isMdSize ? (
                <Fragment>
                  <div style={{flex: 1}}></div>
                  <MenuItem label={t(`menu.home`)} path={HomeRoutePath} onClick={() => toHome()} />
                  <MenuItem label={t(`menu.quemSomos`)} path={`${HomeRoutePath}#${QuemSomosHashName}`} onClick={toQuemSomos} />
                  <MenuItem label={t(`menu.areasAtuacao`)} path={`${HomeRoutePath}#${AreasAtuacaoHashName}`} onClick={toAreasAtuacao} />
                  <MenuItem label={t(`menu.carreiras`)} path={`${HomeRoutePath}#${CarreirasHashName}`} onClick={toCarreiras} />
                  <MenuItem label={t(`menu.contato`)} path={`${HomeRoutePath}#${ContatoHashName}`} onClick={toContato} />
                </Fragment>
              ) : (
                <div
                  className={classes.menuButtonContainer}
                >
                  <IconButton
                    className={classes.menuButton}
                    onClick={() => setLeftMenuOpened(true)}
                  >
                    <MenuIcon
                      className={classes.menuIcon}
                    />
                  </IconButton>
                </div>
              )
            }

          </Container>
        </Toolbar>
      </AppBar>
      {!relativeTopBarHidden && (
        <div className={classes.appBarRelative}>
        </div>
      )}
    </Fragment>
  )
}

export default TopBar;