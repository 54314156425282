import { createStyles, Dialog, IconButton, makeStyles, Slide, Theme, Typography, useTheme } from "@material-ui/core";
import CloseIcon from 'components/Icons/CloseIcon';
import { primaryColor, whiteColor } from 'library/Colors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      paper: {
        position: 'relative',
        borderRadius: 0,
        backgroundColor: primaryColor,
        padding: theme.spacing(3),
        overflow: 'initial',
      },
      title: {
        color: whiteColor,
        fontSize: `18px`,
      },
      text: {
        marginTop: theme.spacing(3),
        color: whiteColor,
        fontSize: `${theme.spacing(2)}px`,
      },
      closeButton: {
        position: 'absolute',
        top: '-15px',
        right: '-15px',
        width: '50px',
        height: '50px',
        backgroundColor: `${whiteColor} !important`,
      },
      closeButtonIcon: {
        fontSize: `${theme.spacing(3)}px`
      }
    }),
);

interface Props {
  modalTextId?: number;
  onClose: () => void;
}

const AreasAtuacaoModal = ({
  modalTextId,
  onClose,
}: Props) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const [opened, setOpened] = useState<boolean>(false);
  const [id, setId] = useState<number>();
  const theme = useTheme();

  useEffect(() => {
    setOpened(!!modalTextId);
  }, [modalTextId, setOpened])

  useEffect(() => {
    if (!!modalTextId) {
      setId(modalTextId);
    }
  }, [modalTextId, setId])

  return (
    <Dialog
      TransitionComponent={Slide}
      transitionDuration={theme.transitions.duration.short}
      TransitionProps={{
        onExited: () => setId(undefined),
      }}
      classes={{
        paper: classes.paper,
      }}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      maxWidth="xs"
      open={opened}>
      <Typography component="h2" className={classes.title} dangerouslySetInnerHTML={{__html: t(`Home.AreasAtuacao.text${id}`)}} />
      <Typography component="p" className={classes.text} dangerouslySetInnerHTML={{__html: t(`Home.AreasAtuacao.modalText${id}`)}} />
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon color="primary" className={classes.closeButtonIcon} />
      </IconButton>
    </Dialog>
  );
}

export default AreasAtuacaoModal;