import React from 'react';
import { IconUIComponentProps, useIconStyle } from './Icon';

const LinkedInIcon = ({ color, size, style, className, noFill }: IconUIComponentProps) => {
  const styles = useIconStyle({ noFill, size, color });
  return (
    <svg
      className={[styles.icon, className].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.73066 18.6663H1.2753V7.53906H4.73066V18.6663ZM3.00112 6.02121C1.89621 6.02121 1 5.10603 1 4.00112C1 3.47039 1.21083 2.9614 1.58611 2.58611C1.9614 2.21083 2.47039 2 3.00112 2C3.53185 2 4.04084 2.21083 4.41612 2.58611C4.7914 2.9614 5.00223 3.47039 5.00223 4.00112C5.00223 5.10603 4.10566 6.02121 3.00112 6.02121ZM17.6629 18.6663H14.215V13.2496C14.215 11.9587 14.189 10.3032 12.4185 10.3032C10.622 10.3032 10.3467 11.7057 10.3467 13.1566V18.6663H6.89509V7.53906H10.2091V9.05692H10.2574C10.7188 8.18266 11.8456 7.26004 13.5268 7.26004C17.0238 7.26004 17.6667 9.56287 17.6667 12.5539V18.6663H17.6629Z" />
    </svg>
  );
}

export default LinkedInIcon;