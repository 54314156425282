import {
  Button,
  createStyles,
  ListSubheader, makeStyles, Theme
} from '@material-ui/core';
import Brand from 'components/Brand';
import PagesContext from 'components/Pages/PagesContext';
import React, { useContext } from 'react';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        border: 'none',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: theme.spacing(12),
      },
      brandButton: {
      },
      brand: {
        fontSize: '109px'
      }
    }),
);

interface Props {
  toClose: () => void;
}

const LeftMenuSubHeader = ({
  toClose,
}: Props) => {
  const classes = useStyles();
  const {toHome} = useContext(PagesContext);

  return (
    <ListSubheader
      component="div"
      className={classes.root}
    >
      <Button
        className={classes.brandButton}
        onClick={() => {
          toClose();
          toHome();
        }}
      >
        <Brand
          className={classes.brand}
        />
      </Button>
    </ListSubheader>
  )
}

export default LeftMenuSubHeader;