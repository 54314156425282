import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { IconUIComponentProps, useIconStyle } from 'components/Icons/Icon';
import { primaryColor, secondaryColor, whiteColor } from 'library/Colors';
import React from 'react';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: '1em',
        height: `${52 * 1 / 178}em`,
      },
    }),
);

interface Props extends IconUIComponentProps {
  inverted?: boolean;
}

const Brand = ({
  color,
  size,
  style,
  className,
  noFill,
  inverted
}: Props) => {
  const classes = useStyles();
  const styles = useIconStyle({ noFill, size, color });
  
  return (
    <svg
      className={[styles.icon, className, classes.root].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 178 52"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M72.9406 33.5646H79.9226C85.5482 33.5646 89.4349 37.4251 89.4349 42.4638V42.516C89.4349 47.5547 85.5482 51.4675 79.9226 51.4675H72.9406V33.5646ZM79.9226 47.9112C83.1441 47.9112 85.3178 45.7375 85.3178 42.5682V42.516C85.3178 39.3467 83.1441 37.1208 79.9226 37.1208H76.8794V47.9112H79.9226Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M93.1172 33.5646H97.056V51.4675H93.1172V33.5646Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M100.738 42.5682V42.516C100.729 41.2849 100.966 40.0645 101.438 38.9271C101.909 37.7898 102.604 36.7588 103.482 35.8954C104.359 35.032 105.402 34.3538 106.546 33.9011C107.691 33.4484 108.915 33.2305 110.146 33.2603C113.368 33.2603 115.311 34.1298 117.176 35.7122L114.698 38.7033C113.316 37.5556 112.09 36.8904 110.016 36.8904C107.155 36.8904 104.877 39.4206 104.877 42.4638V42.516C104.877 45.7897 107.129 48.1938 110.298 48.1938C111.616 48.2331 112.914 47.8575 114.007 47.12V44.5637H110.042V41.1596H117.798V48.9329C115.677 50.781 112.959 51.7974 110.146 51.7935C104.573 51.7935 100.738 47.8808 100.738 42.5682Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M121.758 33.5646H125.697V51.4675H121.758V33.5646Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M134.34 37.1947H128.893V33.5646H143.726V37.1947H138.279V51.4675H134.34V37.1947Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M164.642 33.5646H168.581V47.8808H177.506V51.4631H164.642V33.5646Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M6.34593 0.632568H0.50293V24.8523H19.0579V19.4397H6.34593V0.632568Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M67.3453 27.3868H54.5464V31.3256H67.3453V27.3868Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M61.2371 5.47988C64.2803 5.47988 66.5454 6.94063 67.6844 9.14915L72.6362 6.26244C70.4624 2.62796 66.4019 0.206421 61.2371 0.206421C53.6508 0.206421 48.1295 5.83639 48.1295 12.5663C48.1295 19.7266 53.6508 25.2826 61.2371 25.2826C63.5114 25.315 65.7544 24.7497 67.7417 23.6435C69.7291 22.5373 71.3914 20.9288 72.5623 18.9788L67.6105 16.1442C66.9901 17.2843 66.0664 18.2305 64.9415 18.8781C63.8167 19.5257 62.5346 19.8494 61.2371 19.8135C60.2874 19.8188 59.3461 19.6345 58.4685 19.2712C57.591 18.908 56.7948 18.3732 56.1267 17.6981C55.4585 17.0231 54.9319 16.2215 54.5777 15.3403C54.2234 14.459 54.0487 13.5159 54.0638 12.5663C54.0638 8.5753 56.9983 5.47988 61.2371 5.47988Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M114.098 0.632501L103.377 0.667288V24.8392H114.098C121.65 24.8392 126.884 19.744 126.884 12.6924C126.884 5.71034 122.076 0.597721 114.098 0.632501ZM114.098 19.4397H109.216V6.04946H114.098C118.228 6.04946 120.971 8.36229 120.971 12.7098C120.971 16.7703 118.159 19.4397 114.098 19.4397Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M141.205 0.206469C138.723 0.199586 136.296 0.929076 134.229 2.30259C132.163 3.6761 130.55 5.63188 129.596 7.9223C128.642 10.2127 128.388 12.7348 128.868 15.1692C129.348 17.6037 130.54 19.841 132.292 21.598C134.044 23.3549 136.278 24.5525 138.711 25.0391C141.144 25.5258 143.667 25.2795 145.96 24.3316C148.253 23.3836 150.213 21.7766 151.592 19.714C152.972 17.6513 153.708 15.2258 153.708 12.7446C153.711 9.42421 152.396 6.23835 150.052 3.88725C147.707 1.53615 144.525 0.21223 141.205 0.206469ZM141.205 19.8701C139.799 19.8735 138.423 19.46 137.253 18.6818C136.082 17.9036 135.168 16.7957 134.627 15.4982C134.086 14.2007 133.941 12.7718 134.212 11.3923C134.483 10.0128 135.157 8.74462 136.149 7.7481C137.14 6.75158 138.405 6.0715 139.783 5.79386C141.162 5.51622 142.591 5.65349 143.891 6.1883C145.191 6.72312 146.304 7.63145 147.088 8.79846C147.872 9.96546 148.292 11.3387 148.295 12.7446C148.293 14.6277 147.546 16.4335 146.218 17.7683C144.89 19.1031 143.088 19.8586 141.205 19.8701Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M31.457 0.632568L21.0926 24.8523H27.2877L34.7697 7.25811L42.143 24.8523H48.373L37.9738 0.632568H31.457Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M86.5264 7.25811L93.8997 24.8523H100.134L89.7305 0.632568H83.2136L72.8492 24.8523H79.0444L86.5264 7.25811Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M172.515 17.5529C175.124 16.1617 176.828 13.2054 176.828 9.72743C176.828 4.45395 173.124 0.641235 168.207 0.641235H156.469V24.8392H162.316V6.03208H167.376C168.356 6.03727 169.294 6.43158 169.984 7.12826C170.673 7.82495 171.057 8.76692 171.052 9.74699C171.047 10.7271 170.653 11.6649 169.956 12.3543C169.259 13.0436 168.317 13.428 167.337 13.4228H164.055V18.8354H166.585L170.237 24.8392H177.502L172.515 17.5529Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M148.556 47.4505H148.574L149.969 43.9725H149.965L150.247 43.2769L151.386 40.438H151.412L152.343 38.1643L154.721 43.9725H154.656L156.116 47.4505H156.125L156.56 48.5156L157.799 51.4632H161.881L154.208 33.4342H150.578L142.904 51.4632H146.921L148.556 47.4505Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M72.9406 33.5646H79.9226C85.5482 33.5646 89.4349 37.4251 89.4349 42.4638V42.516C89.4349 47.5547 85.5482 51.4675 79.9226 51.4675H72.9406V33.5646ZM79.9226 47.9112C83.1441 47.9112 85.3178 45.7375 85.3178 42.5682V42.516C85.3178 39.3467 83.1441 37.1208 79.9226 37.1208H76.8794V47.9112H79.9226Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M93.1172 33.5646H97.056V51.4675H93.1172V33.5646Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M100.738 42.5682V42.516C100.729 41.2849 100.966 40.0645 101.438 38.9271C101.909 37.7898 102.604 36.7588 103.482 35.8954C104.359 35.032 105.402 34.3538 106.546 33.9011C107.691 33.4484 108.915 33.2305 110.146 33.2603C113.368 33.2603 115.311 34.1298 117.176 35.7122L114.698 38.7033C113.316 37.5556 112.09 36.8904 110.016 36.8904C107.155 36.8904 104.877 39.4206 104.877 42.4638V42.516C104.877 45.7897 107.129 48.1938 110.298 48.1938C111.616 48.2331 112.914 47.8575 114.007 47.12V44.5637H110.042V41.1596H117.798V48.9329C115.677 50.781 112.959 51.7974 110.146 51.7935C104.573 51.7935 100.738 47.8808 100.738 42.5682Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M121.758 33.5646H125.697V51.4675H121.758V33.5646Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M134.34 37.1947H128.893V33.5646H143.726V37.1947H138.279V51.4675H134.34V37.1947Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M164.642 33.5646H168.581V47.8808H177.506V51.4631H164.642V33.5646Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M6.34593 0.632568H0.50293V24.8523H19.0579V19.4397H6.34593V0.632568Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M67.3453 27.3868H54.5464V31.3256H67.3453V27.3868Z" fill={inverted ? whiteColor : primaryColor} />
      <path d="M61.2371 5.47988C64.2803 5.47988 66.5454 6.94063 67.6844 9.14915L72.6362 6.26244C70.4624 2.62796 66.4019 0.206421 61.2371 0.206421C53.6508 0.206421 48.1295 5.83639 48.1295 12.5663C48.1295 19.7266 53.6508 25.2826 61.2371 25.2826C63.5114 25.315 65.7544 24.7497 67.7417 23.6435C69.7291 22.5373 71.3914 20.9288 72.5623 18.9788L67.6105 16.1442C66.9901 17.2843 66.0664 18.2305 64.9415 18.8781C63.8167 19.5257 62.5346 19.8494 61.2371 19.8135C60.2874 19.8188 59.3461 19.6345 58.4685 19.2712C57.591 18.908 56.7948 18.3732 56.1267 17.6981C55.4585 17.0231 54.9319 16.2215 54.5777 15.3403C54.2234 14.459 54.0487 13.5159 54.0638 12.5663C54.0638 8.5753 56.9983 5.47988 61.2371 5.47988Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M114.098 0.632501L103.377 0.667288V24.8392H114.098C121.65 24.8392 126.884 19.744 126.884 12.6924C126.884 5.71034 122.076 0.597721 114.098 0.632501ZM114.098 19.4397H109.216V6.04946H114.098C118.228 6.04946 120.971 8.36229 120.971 12.7098C120.971 16.7703 118.159 19.4397 114.098 19.4397Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M141.205 0.206469C138.723 0.199586 136.296 0.929076 134.229 2.30259C132.163 3.6761 130.55 5.63188 129.596 7.9223C128.642 10.2127 128.388 12.7348 128.868 15.1692C129.348 17.6037 130.54 19.841 132.292 21.598C134.044 23.3549 136.278 24.5525 138.711 25.0391C141.144 25.5258 143.667 25.2795 145.96 24.3316C148.253 23.3836 150.213 21.7766 151.592 19.714C152.972 17.6513 153.708 15.2258 153.708 12.7446C153.711 9.42421 152.396 6.23835 150.052 3.88725C147.707 1.53615 144.525 0.21223 141.205 0.206469ZM141.205 19.8701C139.799 19.8735 138.423 19.46 137.253 18.6818C136.082 17.9036 135.168 16.7957 134.627 15.4982C134.086 14.2007 133.941 12.7718 134.212 11.3923C134.483 10.0128 135.157 8.74462 136.149 7.7481C137.14 6.75158 138.405 6.0715 139.783 5.79386C141.162 5.51622 142.591 5.65349 143.891 6.1883C145.191 6.72312 146.304 7.63145 147.088 8.79846C147.872 9.96546 148.292 11.3387 148.295 12.7446C148.293 14.6277 147.546 16.4335 146.218 17.7683C144.89 19.1031 143.088 19.8586 141.205 19.8701Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M31.457 0.632568L21.0926 24.8523H27.2877L34.7697 7.25811L42.143 24.8523H48.373L37.9738 0.632568H31.457Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M86.5264 7.25811L93.8997 24.8523H100.134L89.7305 0.632568H83.2136L72.8492 24.8523H79.0444L86.5264 7.25811Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M172.515 17.5529C175.124 16.1617 176.828 13.2054 176.828 9.72743C176.828 4.45395 173.124 0.641235 168.207 0.641235H156.469V24.8392H162.316V6.03208H167.376C168.356 6.03727 169.294 6.43158 169.984 7.12826C170.673 7.82495 171.057 8.76692 171.052 9.74699C171.047 10.7271 170.653 11.6649 169.956 12.3543C169.259 13.0436 168.317 13.428 167.337 13.4228H164.055V18.8354H166.585L170.237 24.8392H177.502L172.515 17.5529Z" fill={inverted ? secondaryColor : whiteColor} />
      <path d="M148.556 47.4505H148.574L149.969 43.9725H149.965L150.247 43.2769L151.386 40.438H151.412L152.343 38.1643L154.721 43.9725H154.656L156.116 47.4505H156.125L156.56 48.5156L157.799 51.4632H161.881L154.208 33.4342H150.578L142.904 51.4632H146.921L148.556 47.4505Z" fill={inverted ? whiteColor : primaryColor} />
    </svg>
  );
}

export default Brand;