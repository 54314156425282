import React from 'react';
import { IconUIComponentProps, useIconStyle } from './Icon';

const MapIcon = ({ color, size, style, className, noFill }: IconUIComponentProps) => {
  const styles = useIconStyle({ noFill, size, color });
  return (
    <svg
      className={[styles.icon, className].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 2C6.65625 2 4 4.71875 4 8C4 10.25 4.71875 10.9375 8.71875 17.3125C9.3125 18.25 10.6562 18.25 11.25 17.3125C15.25 10.9375 16 10.25 16 8C16 4.6875 13.2812 2 10 2ZM10 16.5C6 10.125 5.5 9.71875 5.5 8C5.5 5.53125 7.5 3.5 10 3.5C12.4688 3.5 14.5 5.53125 14.5 8C14.5 9.71875 14 10.0625 10 16.5ZM7.5 8C7.5 9.40625 8.59375 10.5 10 10.5C11.375 10.5 12.5 9.40625 12.5 8C12.5 6.625 11.375 5.5 10 5.5C8.59375 5.5 7.5 6.625 7.5 8Z" />
    </svg>
  );
}

export default MapIcon;