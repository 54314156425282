import { Button, createStyles, Link as MaterialLink, makeStyles, Theme } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Link } from "react-router-dom";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'initial',
        textDecoration: 'none !important',
        '@media (max-width: 1030px)': {
          fontSize: '14px'
        }
      },
    }),
);

interface Props {
  path?: string;
  label: string;
  onClick?: Function;
  link?: string;
}

const MenuItem = ({
  path,
  label,
  onClick,
  link,
}: Props) => {
  const classes = useStyles();

  const toClick = useCallback((e: any) => {
    if (typeof onClick === 'function') {
      e.preventDefault();
      onClick();
    }
  }, [onClick])

  if (link) {
    return (
      <Button
        color="secondary"
        component={MaterialLink}
        href={link}
        target="_blank"
        onClick={(e: any) => toClick(e)}
        className={classes.root}
      >
        {label}
      </Button>
    )
  }

  if (path) {
    return (
      <Button
        color="secondary"
        component={Link}
        to={path}
        onClick={(e: any) => toClick(e)}
        className={classes.root}
      >
        {label}
      </Button>
    )
  }

  return (
    <Button
      color="secondary"
      onClick={(e: any) => toClick(e)}
      className={classes.root}
    >
      {label}
    </Button>
  )
}

export default MenuItem;