import { CircularProgress, createStyles, MuiThemeProvider, makeStyles } from '@material-ui/core';
import Layout from 'components/Layout';
import { LayoutMuiTheme } from 'components/Layout/LayoutMuiTheme';
import { DATE_UPDATE, VERSION } from 'config';
import { IAppContext } from 'interfaces/IAppContext';
import { getAllRouteComponents } from 'library/Routes';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Events } from 'react-scroll';
import AppContext from './AppContext';

console.log(`version: ${VERSION}`)
console.log(`update: ${DATE_UPDATE}`)

const useStyles = makeStyles(
  () =>
    createStyles({
      circularProgress: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    }),
);

function App() {
  const classes = useStyles();
  const [scrollAnimating, setScrollAnimating] = useState<boolean>(false);

  const contextValue = useMemo<IAppContext>(() => {
    return {
      scrollAnimating
    }
  }, [scrollAnimating])

  useEffect(() => {
    Events.scrollEvent.register('begin', () => setScrollAnimating(true));
    Events.scrollEvent.register('end', () => setScrollAnimating(false));
  }, [setScrollAnimating]);

  return (
    <AppContext.Provider value={contextValue}>
      <MuiThemeProvider theme={LayoutMuiTheme}>
        <Suspense fallback={<CircularProgress className={classes.circularProgress} />}>
          <Router>
            <Layout>
              {getAllRouteComponents()}
            </Layout>
          </Router>
        </Suspense>
      </MuiThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
