import { createContext } from "react";
import {ILayoutContext} from 'interfaces/ILayoutContext';

export default createContext({
  isXsSize: false,
  isSmSize: false,
  isMdSize: false,
  isLgSize: false,
  isXlSize: false,
  leftMenuOpened: false,
  setLeftMenuOpened: () => {},
  relativeTopBarHidden: false,
  setRelativeTopBarHidden: () => {},
  topBarHeight: 0,
  whiteColorLinkClassName: '',
  secondaryColorLinkClassName: '',
  scrolled: false,
  maxHeight: window.innerHeight,
} as ILayoutContext);