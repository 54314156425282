import { createStyles, makeStyles, Theme } from "@material-ui/core";
import LayoutContext from "components/Layout/LayoutContext";
import { Fragment, useContext, useEffect } from "react";
import AreasAtuacao from './AreasAtuacao';
import Carreiras from './Carreiras';
import Contato from './Contato';
import Lacador from './Lacador';
import QuemSomos from './QuemSomos';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      session: {
        position: 'relative',
        marginTop: '-1px',
        overflow: 'hidden',
        zIndex: 1,
        '&:first-child': {
          marginTop: 0
        }
      },
    }),
);

const Home = () => {
  const classes = useStyles();
  const { setRelativeTopBarHidden } = useContext(LayoutContext);

  useEffect(() => {
    setRelativeTopBarHidden(true);
    return () => setRelativeTopBarHidden(false);
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Lacador className={classes.session} />
      <QuemSomos className={classes.session} />
      <AreasAtuacao className={classes.session} />
      <Carreiras className={classes.session} />
      <Contato className={classes.session} />
    </Fragment>
  )
}

export default Home;