import { createContext } from "react";
import { IPagesContext } from "../../interfaces/IPagesContext";

export default createContext<IPagesContext>({
  inHome: false,
  inQuemSomos: false,
  inAreasAtuacao: false,
  inCarreiras: false,
  inContato: false,
  toHome: () => {},
  toQuemSomos: () => {},
  toAreasAtuacao: () => {},
  toCarreiras: () => {},
  toContato: () => {},
});