import { Button, Container, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import CustomTooltip from 'components/CustomTooltip';
import InstagramIcon from "components/Icons/InstagramIcon";
import LinkedInIcon from "components/Icons/LinkedInIcon";
import LayoutContext from "components/Layout/LayoutContext";
import MiniBrand from 'components/MiniBrand';
import PagesContext from "components/Pages/PagesContext";
import Links from 'enuns/Links';
import { secondaryColor, whiteColor } from "library/Colors";
import { AreasAtuacaoHashName, CarreirasHashName, HomeRoutePath, QuemSomosHashName } from "library/Routes";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        width: '100%',
        backgroundColor: secondaryColor,
        paddingTop: theme.spacing(7),
        paddingBottom: theme.spacing(7),
      },
      columns: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        }
      },
      columnItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(0, 3),
        '&:first-child': {
          paddingLeft: 0,
        },
        '&:last-child': {
          paddingRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
          alignItems: 'center',
          padding: theme.spacing(3, 0),
          '&:first-child': {
            paddingTop: 0,
          },
          '&:last-child': {
            paddingBottom: 0,
          },
        }
      },
      miniBrand: {
        fontSize: '72px',
      },
      description: {
        marginTop: theme.spacing(2),
        fontSize: '14px',
        color: whiteColor,
        [theme.breakpoints.up('sm')]: {
          whiteSpace: 'pre',
        },
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
      menuItem: {
        fontSize: '14px',
        lineHeight: '20px',
        marginTop: '20px',
        fontWeight: 600,
        textDecoration: 'none',
        display: 'inline-block',
        '&:first-child': {
          marginTop: 0
        }
      },
      rightInfoItem: {
        color: whiteColor,
        marginTop: '20px',
        '&:first-child': {
          marginTop: 0
        },
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
      rightInfoTitle: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 600,
      },
      rightInfoText: {
        fontSize: '14px',
        lineHeight: '20px',
      },
      socialIconsContent: {
        paddingTop: theme.spacing(0.7),
      },
      socialIconButton: {
        minWidth: 'initial',
        padding: theme.spacing(0.5),
        [theme.breakpoints.up('md')]: {
          '&:first-child': {
            marginLeft: theme.spacing(-0.5),
          },
        },
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(0, 1)
        },
      },
      socialIcon: {
        fontSize: '20px',
      },
    }),
);


const Footer = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const {toQuemSomos, toAreasAtuacao, toCarreiras} = useContext(PagesContext);
  const {whiteColorLinkClassName} = useContext(LayoutContext);

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <div className={classes.columns}>
          <div className={classes.columnItem}>
            <MiniBrand className={classes.miniBrand} />
            <Typography component="p" className={classes.description} dangerouslySetInnerHTML={{__html: t(`Footer.description`)}} />
          </div>
          <div className={classes.columnItem}>
            <Link
              className={[
                classes.menuItem,
                whiteColorLinkClassName,
              ].join(' ')}
              to={`${HomeRoutePath}#${QuemSomosHashName}`}
              onClick={toQuemSomos}
            >
              {t(`menu.quemSomos`)}
            </Link>
            <Link
              className={[
                classes.menuItem,
                whiteColorLinkClassName,
              ].join(' ')}
              to={`${HomeRoutePath}#${AreasAtuacaoHashName}`}
              onClick={toAreasAtuacao}
            >
              {t(`menu.areasAtuacao`)}
            </Link>
            <Link
              className={[
                classes.menuItem,
                whiteColorLinkClassName,
              ].join(' ')}
              to={`${HomeRoutePath}#${CarreirasHashName}`}
              onClick={toCarreiras}
            >
              {t(`menu.carreiras`)}
            </Link>
          </div>
          <div className={classes.columnItem}>
            <div className={classes.rightInfoItem}>
              <div className={classes.rightInfoTitle}>
                {t(`Footer.location.title`)}
              </div>
              <div className={classes.rightInfoText} dangerouslySetInnerHTML={{__html: t(`Footer.location.text`)}} />
            </div>
            {/* <div className={classes.rightInfoItem}>
              <div className={classes.rightInfoTitle}>
                {t(`Footer.whatsApp.title`)}
              </div>
              <div className={classes.rightInfoText} dangerouslySetInnerHTML={{__html: t(`Footer.whatsApp.text`)}} />
            </div> */}
            <div className={classes.rightInfoItem}>
              <div className={classes.rightInfoTitle}>
                {t(`Footer.redesSociais.title`)}
              </div>
              <div className={classes.socialIconsContent}>
                {/* <Button className={classes.socialIconButton}>
                  <WhatsAppIcon className={classes.socialIcon} component="a" color="primary" />
                </Button> */}
                <CustomTooltip title={t(`Footer.commingSoon`).toString()}>
                  <Button className={classes.socialIconButton}>
                    <InstagramIcon className={classes.socialIcon} color="primary" />
                  </Button>
                </CustomTooltip>
                <Button className={classes.socialIconButton} component="a" href={Links.LinkedIn} target="_blank" >
                  <LinkedInIcon className={classes.socialIcon} color="primary" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer;