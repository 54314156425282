import React from 'react';
import { IconUIComponentProps, useIconStyle } from './Icon';

const CloseIcon = ({ color, size, style, className, noFill }: IconUIComponentProps) => {
  const styles = useIconStyle({ noFill, size, color });
  return (
    <svg
      className={[styles.icon, className].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M1.2929 1.29289C1.68342 0.902369 2.31659 0.902369 2.70711 1.29289L22.1109 20.6967C22.5014 21.0872 22.5014 21.7204 22.1109 22.1109C21.7204 22.5014 21.0872 22.5014 20.6967 22.1109L1.2929 2.70711C0.902372 2.31658 0.902372 1.68342 1.2929 1.29289Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.1109 1.29289C22.5014 1.68342 22.5014 2.31658 22.1109 2.70711L2.70711 22.1109C2.31658 22.5014 1.68342 22.5014 1.29289 22.1109C0.902369 21.7204 0.902369 21.0872 1.29289 20.6967L20.6967 1.29289C21.0872 0.902369 21.7204 0.902369 22.1109 1.29289Z" />
    </svg>
  );
}

export default CloseIcon;