import React from 'react';
import { IconUIComponentProps, useIconStyle } from './Icon';

const MenuIcon = ({ color, size, style, className, noFill }: IconUIComponentProps) => {
  const styles = useIconStyle({ noFill, size, color });
  return (
    <svg
      className={[styles.icon, className].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M18.2164 33.4329C26.6202 33.4329 33.4329 26.6202 33.4329 18.2164C33.4329 9.81263 26.6202 3 18.2164 3C9.81263 3 3 9.81263 3 18.2164C3 26.6202 9.81263 33.4329 18.2164 33.4329ZM18.2164 35.4329C27.7248 35.4329 35.4329 27.7248 35.4329 18.2164C35.4329 8.70806 27.7248 1 18.2164 1C8.70806 1 1 8.70806 1 18.2164C1 27.7248 8.70806 35.4329 18.2164 35.4329Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.81885 15.2287L9.23306 13.8145L18.2165 22.7979L27.1999 13.8145L28.6141 15.2287L18.2165 25.6263L7.81885 15.2287Z" />
    </svg>
  );
}

export default MenuIcon;