import { createStyles, Drawer, List, ListItem, makeStyles, Theme } from "@material-ui/core";
import LayoutContext from "components/Layout/LayoutContext";
import PagesContext from "components/Pages/PagesContext";
import { secondaryColor, whiteColor } from "library/Colors";
import { AreasAtuacaoRoutePath, CarreirasRoutePath, ContatoRoutePath, HomeRoutePath, QuemSomosRoutePath } from "library/Routes";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import LeftMenuSubHeader from './LeftMenuSubHeader';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: secondaryColor,
      },
      menuItem: {
        color: whiteColor,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        textDecoration: 'none !important',
        padding: theme.spacing(2, 3)
      }
    }),
);

const LeftMenu = () => {
  const {isXsSize, isSmSize, leftMenuOpened, setLeftMenuOpened} = useContext(LayoutContext);
  const {toHome, toQuemSomos, toAreasAtuacao, toCarreiras, toContato} = useContext(PagesContext);
  const {t} = useTranslation();
  const classes = useStyles();

  const toClose = useCallback(() => {
    setLeftMenuOpened(false);
  }, [setLeftMenuOpened])

  return isSmSize || isXsSize ? (
    <Drawer
      anchor="left"
      variant="temporary"
      open={leftMenuOpened}
      onClose={() => setLeftMenuOpened(false)}
      classes={{
        paper: classes.root
      }}
    >
      <List component="nav" subheader={<LeftMenuSubHeader toClose={toClose} />}>
        <ListItem
          component={Link}
          to={HomeRoutePath}
          onClick={(e: any) => {
            e.preventDefault();
            toClose();
            toHome();
          }}
          className={classes.menuItem}
        >
          {t(`menu.home`)}
        </ListItem>
        <ListItem
          component={Link}
          to={QuemSomosRoutePath}
          onClick={(e: any) => {
            e.preventDefault();
            toClose();
            toQuemSomos();
          }}
          className={classes.menuItem}
        >
          {t(`menu.quemSomos`)}
        </ListItem>
        <ListItem
          component={Link}
          to={AreasAtuacaoRoutePath}
          onClick={(e: any) => {
            e.preventDefault();
            toClose();
            toAreasAtuacao();
          }}
          className={classes.menuItem}
        >
          {t(`menu.areasAtuacao`)}
        </ListItem>
        <ListItem
          component={Link}
          to={CarreirasRoutePath}
          onClick={(e: any) => {
            e.preventDefault();
            toClose();
            toCarreiras();
          }}
          className={classes.menuItem}
        >
          {t(`menu.carreiras`)}
        </ListItem>
        <ListItem
          component={Link}
          to={ContatoRoutePath}
          onClick={(e: any) => {
            e.preventDefault();
            toClose();
            toContato();
          }}
          className={classes.menuItem}
        >
          {t(`menu.contato`)}
        </ListItem>
      </List>
    </Drawer>
  ) : null;
}

export default LeftMenu;