import React from 'react';
import { IconUIComponentProps, useIconStyle } from './Icon';

const MenuIcon = ({ color, size, style, className, noFill }: IconUIComponentProps) => {
  const styles = useIconStyle({ noFill, size, color });
  return (
    <svg
      className={[styles.icon, className].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M2.00003 9C2.00003 8.44772 2.44775 8 3.00003 8H28.2804C28.8327 8 29.2804 8.44772 29.2804 9C29.2804 9.55228 28.8327 10 28.2804 10H3.00003C2.44775 10 2.00003 9.55228 2.00003 9ZM2 16.2715C2 15.7192 2.44772 15.2715 3 15.2715H28.2804C28.8326 15.2715 29.2804 15.7192 29.2804 16.2715C29.2804 16.8238 28.8326 17.2715 28.2804 17.2715H3C2.44772 17.2715 2 16.8238 2 16.2715ZM2 23.5429C2 22.9907 2.44772 22.5429 3 22.5429H28.2804C28.8326 22.5429 29.2804 22.9907 29.2804 23.5429C29.2804 24.0952 28.8326 24.5429 28.2804 24.5429H3C2.44772 24.5429 2 24.0952 2 23.5429Z" />
    </svg>
  );
}

export default MenuIcon;