import { useMediaQuery, useTheme } from "@material-ui/core";
import Footer from 'components/Footer';
import LeftMenu from 'components/LeftMenu';
import Pages from 'components/Pages';
import TopBar from 'components/TopBar';
import { ILayoutContext } from "interfaces/ILayoutContext";
import { useEffect, useMemo, useState } from "react";
import { Switch } from "react-router";
import linkStyle from 'styles/link';
import LayoutContext from './LayoutContext';

interface Props {
  children?: any
}

const Layout = ({
  children,
}: Props) => {
  const theme = useTheme();
  const isXsSize = useMediaQuery(theme.breakpoints.up('xs'));
  const isSmSize = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdSize = useMediaQuery(theme.breakpoints.up('md'));
  const isLgSize = useMediaQuery(theme.breakpoints.up('lg'));
  const isXlSize = useMediaQuery(theme.breakpoints.up('xl'));
  const [leftMenuOpened, setLeftMenuOpened] = useState<boolean>(false);
  const [relativeTopBarHidden, setRelativeTopBarHidden] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [maxHeight, setMaxHeight] = useState<number>(window.innerHeight);
  const linkClasses = linkStyle();
  
  const whiteColorLinkClassName = `${linkClasses.root} ${linkClasses.whiteColorLink}`;
  const secondaryColorLinkClassName = `${linkClasses.root} ${linkClasses.secondaryColorLink}`;

  const topBarHeight = useMemo<number>(() => {
    return isMdSize ? (scrolled ? 96 : 150) : 56;
  }, [isMdSize, scrolled])

  const contextValue = useMemo<ILayoutContext>(() => {
    return {
      isXsSize,
      isSmSize,
      isMdSize,
      isLgSize,
      isXlSize,
      leftMenuOpened,
      setLeftMenuOpened,
      relativeTopBarHidden,
      setRelativeTopBarHidden,
      topBarHeight,
      whiteColorLinkClassName,
      secondaryColorLinkClassName,
      scrolled,
      maxHeight,
    }
  }, [
    isXsSize,
    isSmSize,
    isMdSize,
    isLgSize,
    isXlSize,
    leftMenuOpened,
    setLeftMenuOpened,
    relativeTopBarHidden,
    setRelativeTopBarHidden,
    topBarHeight,
    whiteColorLinkClassName,
    secondaryColorLinkClassName,
    scrolled,
    maxHeight,
  ])

  const onScroll = () => {
    setScrolled(window.scrollY > 0);
    setMaxHeight(window.innerHeight);
  }

  useEffect(() => {
    setScrolled(window.scrollY > 0);
    setMaxHeight(window.innerHeight);
    window.document.addEventListener('scroll', onScroll);
    return () => {
      window.document.removeEventListener('scroll', onScroll);
    }
  }, [])

  return (
    <LayoutContext.Provider value={contextValue}>
      <Pages>
        <TopBar />
        <Switch>
          {children}
        </Switch>
        <Footer />
        <LeftMenu />
      </Pages>
    </LayoutContext.Provider>
  )
}

export default Layout;