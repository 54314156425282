import React from 'react';
import { IconUIComponentProps, useIconStyle } from './Icon';

const InstagramIcon = ({ color, size, style, className, noFill }: IconUIComponentProps) => {
  const styles = useIconStyle({ noFill, size, color });
  return (
    <svg
      className={[styles.icon, className].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.33519 6.0593C6.97044 6.0593 5.06302 7.96672 5.06302 10.3315C5.06302 12.6962 6.97044 14.6036 9.33519 14.6036C11.6999 14.6036 13.6074 12.6962 13.6074 10.3315C13.6074 7.96672 11.6999 6.0593 9.33519 6.0593ZM9.33519 13.1089C7.80703 13.1089 6.55773 11.8634 6.55773 10.3315C6.55773 8.79959 7.80331 7.55401 9.33519 7.55401C10.8671 7.55401 12.1127 8.79959 12.1127 10.3315C12.1127 11.8634 10.8634 13.1089 9.33519 13.1089V13.1089ZM14.7786 5.88455C14.7786 6.43856 14.3324 6.88102 13.7821 6.88102C13.2281 6.88102 12.7856 6.43484 12.7856 5.88455C12.7856 5.33426 13.2318 4.88808 13.7821 4.88808C14.3324 4.88808 14.7786 5.33426 14.7786 5.88455ZM17.6081 6.89589C17.5449 5.56107 17.24 4.3787 16.2621 3.40454C15.288 2.43038 14.1056 2.12549 12.7708 2.05856C11.3951 1.98048 7.27161 1.98048 5.89589 2.05856C4.56479 2.12177 3.38241 2.42666 2.40454 3.40082C1.42666 4.37498 1.12549 5.55735 1.05856 6.89217C0.98048 8.26789 0.98048 12.3913 1.05856 13.7671C1.12177 15.1019 1.42666 16.2843 2.40454 17.2584C3.38241 18.2326 4.56107 18.5375 5.89589 18.6044C7.27161 18.6825 11.3951 18.6825 12.7708 18.6044C14.1056 18.5412 15.288 18.2363 16.2621 17.2584C17.2363 16.2843 17.5412 15.1019 17.6081 13.7671C17.6862 12.3913 17.6862 8.27161 17.6081 6.89589V6.89589ZM15.8308 15.2432C15.5408 15.9719 14.9794 16.5334 14.2469 16.8271C13.15 17.2621 10.5473 17.1617 9.33519 17.1617C8.12307 17.1617 5.51664 17.2584 4.4235 16.8271C3.69474 16.5371 3.1333 15.9756 2.83956 15.2432C2.40454 14.1463 2.50493 11.5436 2.50493 10.3315C2.50493 9.11935 2.40825 6.51292 2.83956 5.41978C3.12958 4.69102 3.69102 4.12958 4.4235 3.83584C5.52036 3.40082 8.12307 3.50121 9.33519 3.50121C10.5473 3.50121 13.1537 3.40454 14.2469 3.83584C14.9756 4.12586 15.5371 4.6873 15.8308 5.41978C16.2659 6.51664 16.1655 9.11935 16.1655 10.3315C16.1655 11.5436 16.2659 14.15 15.8308 15.2432Z" />
    </svg>
  );
}

export default InstagramIcon;