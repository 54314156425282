import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { IconUIComponentProps, useIconStyle } from 'components/Icons/Icon';
import React, { Fragment, useMemo } from 'react';
import { getUniqueKey } from 'utils/KeyUtils';

const useStyles = (width: number, height: number) => makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: `${width * 1 / height}em`,
        height: '1em'
      },
    }),
);

interface Props extends IconUIComponentProps {
  imageUrl?: string;
  onClick?: Function;
  rotated?: boolean;
}

const Honeycomb = ({
  color,
  size,
  style,
  className,
  noFill,
  imageUrl,
  onClick,
  rotated,
}: Props) => {
  
  const width = useMemo<number>(() => {
    return rotated ? 153 : 232;
  }, [rotated]);

  const height = useMemo<number>(() => {
    return rotated ? 175 : 202;
  }, [rotated]);

  const classes = useStyles(width, height)();
  const styles = useIconStyle({ noFill, size, color });
  const filterHash: string = getUniqueKey();
  const maskHash: string = getUniqueKey();
  const patternHash: string = getUniqueKey();
  const effectDropShadowHash: string = getUniqueKey();
  const backgroundImageFixHash: string = getUniqueKey();

  return (
    <svg
      className={[styles.icon, className, classes.root].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter={`url(#${filterHash})`} onClick={() => typeof onClick === 'function' ? onClick() : false}>
        {
          imageUrl ? (
            <Fragment>
              {
                rotated ? (
                  <mask id={maskHash} mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="0" width="144" height="167">
                    <path d="M4.35468 41.9907L76.1718 0.524655L148 41.9907L148 124.923L76.1718 166.389L4.35467 124.923L4.35468 41.9907Z" fill="#D8D8D8"/>
                  </mask>
                ) : (
                  <mask id={maskHash} mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="0" width="224" height="194">
                    <path d="M59.9282 193.745L4 96.8799L59.9282 0H171.785L227.713 96.8799L171.785 193.745H59.9282Z" fill="#D8D8D8" />
                  </mask>
                )
              }
              <g mask={`url(#${maskHash})`}>
                {
                  rotated ? (
                    <rect x="-45.6622" y="0.524902" width="243.679" height="165.864" fill={`url(#${patternHash})`}/>
                  ) : (
                    <rect x="4" width="223.71" height="193.75" fill={`url(#${patternHash})`}/>
                  )
                }
              </g>
            </Fragment>
          ) : (
            <Fragment>
              {
                rotated ? (
                  <path d="M4.60173 41.9908L76.4187 0.524899L148.247 41.9908L148.247 124.923L76.4187 166.388L4.60172 124.923L4.60173 41.9908Z"/>
                ) : (
                  <path d="M59.9282 193.745L4 96.8799L59.9282 0H171.785L227.713 96.8799L171.785 193.745H59.9282Z" />
                )
              }
            </Fragment>
          )
        }
      </g>
      <defs>
        <filter id={filterHash} x="0" y="0" width="231.71" height="201.745" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result={backgroundImageFixHash} />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2={backgroundImageFixHash} result={effectDropShadowHash} />
          <feBlend mode="normal" in="SourceGraphic" in2={effectDropShadowHash} result="shape"/>
        </filter>
        { !!imageUrl && (
          <Fragment>
            <pattern id={patternHash} patternUnits="userSpaceOnUse" width={width} height={height}>
              <image width="232" height="202" xlinkHref={imageUrl} />
            </pattern>
          </Fragment>
        ) }
      </defs>
    </svg>
  );
}

export default Honeycomb;