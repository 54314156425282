import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { secondaryColor, whiteColor } from "library/Colors";

const link = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        textDecoration: 'none',
        '&:after': {
          content: '""',
          position: 'absolute',
          left: '50%',
          bottom: 0,
          width: '0',
          height: '1px',
          transform: 'translateX(-50%)',
          backgroundColor: 'inherit',
          transition: theme.transitions.create(['width'], {
            duration: theme.transitions.duration.short
          })
        },
        '&:hover': {
          '&:after': {
            width: '100%',
          }
        }
      },
      whiteColorLink: {
        color: whiteColor,
        '&:after': {
          backgroundColor: whiteColor,
        }
      },
      secondaryColorLink: {
        color: secondaryColor,
        '&:after': {
          backgroundColor: secondaryColor,
        }
      }
    }),
);

export default link;