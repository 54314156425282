import { Container, createStyles, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Honeycomb from 'components/Honeycomb';
import LayoutContext from 'components/Layout/LayoutContext';
import { secondaryColor, whiteColor } from 'library/Colors';
import { AreasAtuacaoHashName } from 'library/Routes';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import AreasAtuacaoModal from './AreasAtuacaoModal';

const useStyles = (topBarHeight: number) => makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        backgroundColor: secondaryColor,
        width: '100%',
        backgroundImage: `url(assets/images/areas-atuacao-bg.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        overflow: 'hidden',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: theme.spacing(4),
          left: 0,
          width: '100%',
          height: '130px',
          backgroundImage: 'url(assets/images/areas-atuacao-linear-gradient.png)',
          backgroundSize: '100% 100%',
          backgroundPosition: 'top left',
          zIndex: 50,
          [theme.breakpoints.down(665)]: {
            content: 'none',
          }
        }
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      title: {
        position: 'relative',
        zIndex: 100,
        fontSize: `${theme.spacing(3)}px`,
        fontWeight: 700,
        textAlign: 'center',
        marginTop: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
        }
      },
      honeycombContent: {
        marginTop: theme.spacing(6),
        width: '100%',
        position: 'relative',
        height: '470px',
        [theme.breakpoints.down(1000)]: {
          height: `${470 * 100 / 1000}vw`
        },
        [theme.breakpoints.down(665)]: {
          marginTop: theme.spacing(3),
          height: '600px',
        }
      },
      honeycombItem: {
        position: 'absolute',
      },
      honeycomb: {
        fontSize: '202px',
        [theme.breakpoints.down(1000)]: {
          fontSize: `${202 * 100 / 1000}vw`
        },
        [theme.breakpoints.down(665)]: {
          fontSize: `166px`
        },
      },
      honeycombTextContent: {
        fontSize: `202px`,
        width: `${232 * 1 / 202}em`,
        height: '1em',
        [theme.breakpoints.down(1000)]: {
          fontSize: `${202 * 100 / 1000}vw`
        },
        [theme.breakpoints.down(665)]: {
          width: `${153 * 1 / 175}em`,
          fontSize: `166px`
        },
        '& > svg': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },
      honeycombText: {
        position: 'absolute',
        width: '100%',
        padding: theme.spacing(1, 1, 2, 1),
        boxSizing: 'border-box',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -45%)',
        fontSize: '18px',
        fontWeight: 600,
        color: whiteColor,
        textAlign: 'center',
        lineHeight: '1.3em',
        whiteSpace: 'pre',
        [theme.breakpoints.down(1000)]: {
          fontSize: `${18 * 100 / 1000}vw`,
        },
        [theme.breakpoints.down(665)]: {
          fontSize: `14px`,
          padding: theme.spacing(1, 2.5, 5, 2.5),
          whiteSpace: 'break-spaces',
        },
      },
      honeycombButton: {
        position: 'absolute',
        bottom: '35px',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '12px',
        cursor: 'pointer',
        zIndex: 3,
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
        [theme.breakpoints.down(665)]: {
          bottom: theme.spacing(5)
        },
      },
      honeycombItem1: {
        top: '-103px',
        left: 'calc(50% - 294px)',
        opacity: 0.1,
        [theme.breakpoints.down(1000)]: {
          top: `${(103 * 100 / 1000) * -1}vw`,
          left: `calc(50% - ${294 * 100 / 1000}vw)`,
        },
        [theme.breakpoints.down(665)]: {
          top: `${126 * 2}px`,
          left: `50%`,
          transform: `translateX(25%)`,
          opacity: 0.45,
        }
      },
      honeycombItem2: {
        top: '-103px',
        right: 'calc(50% - 294px)',
        opacity: 0.1,
        [theme.breakpoints.down(1000)]: {
          top: `${(103 * 100 / 1000) * -1}vw`,
          right: `calc(50% - ${294 * 100 / 1000}vw)`,
        },
        [theme.breakpoints.down(665)]: {
          display: 'none',
        }
      },
      honeycombItem3: {
        top: '0',
        left: 'calc(50% - 472px)',
        [theme.breakpoints.down(1000)]: {
          left: `calc(50% - ${472 * 100 / 1000}vw)`,
        },
        [theme.breakpoints.down(665)]: {
          top: 0,
          left: `50%`,
          transform: `translateX(25%)`
        }
      },
      honeycombItem4: {
        top: '0',
        transform: 'translateX(-50%)',
        left: '50%',
        [theme.breakpoints.down(665)]: {
          top: `${126 * 1}px`,
          left: `50%`,
          transform: `translateX(-25%)`
        }
      },
      honeycombItem5: {
        top: '0',
        right: 'calc(50% - 472px)',
        [theme.breakpoints.down(1000)]: {
          right: `calc(50% - ${472 * 100 / 1000}vw)`,
        },
        [theme.breakpoints.down(665)]: {
          top: `${126 * 3}px`,
          left: `50%`,
          transform: `translateX(-25%)`
        }
      },
      honeycombItem6: {
        top: '103px',
        left: 'calc(50% - 294px)',
        [theme.breakpoints.down(1000)]: {
          top: `${103 * 100 / 1000}vw`,
          left: `calc(50% - ${294 * 100 / 1000}vw)`,
        },
        [theme.breakpoints.down(665)]: {
          top: 0,
          left: `50%`,
          transform: `translateX(-75%)`
        }
      },
      honeycombItem7: {
        top: '103px',
        right: 'calc(50% - 294px)',
        [theme.breakpoints.down(1000)]: {
          top: `${103 * 100 / 1000}vw`,
          right: `calc(50% - ${294 * 100 / 1000}vw)`,
        },
        [theme.breakpoints.down(665)]: {
          top: `${126 * 2}px`,
          left: `50%`,
          transform: `translateX(-75%)`
        }
      },
      honeycombItem8: {
        top: '202px',
        left: 'calc(50% - 472px)',
        opacity: 0.3,
        [theme.breakpoints.down(1000)]: {
          top: `${202 * 100 / 1000}vw`,
          left: `calc(50% - ${472 * 100 / 1000}vw)`,
        },
        [theme.breakpoints.down(665)]: {
          top: `${126 * 3}px`,
          left: `50%`,
          transform: `translateX(-125%)`,
          opacity: 0.45,
        }
      },
      honeycombItem9: {
        top: '202px',
        left: 'calc(50%)',
        transform: 'translateX(-50%)',
        [theme.breakpoints.down(1000)]: {
          top: `${202 * 100 / 1000}vw`,
        },
        [theme.breakpoints.down(665)]: {
          top: `${126 * 1}px`,
          left: `50%`,
          transform: `translateX(-125%)`
        }
      },
      honeycombItem10: {
        top: '202px',
        right: 'calc(50% - 472px)',
        opacity: 0.3,
        [theme.breakpoints.down(1000)]: {
          top: `${202 * 100 / 1000}vw`,
          right: `calc(50% - ${472 * 100 / 1000}vw)`,
        },
        [theme.breakpoints.down(665)]: {
          display: 'none',
        }
      },
    }),
);

interface Props {
  className?: string;
}

const AreasAtuacao = ({className}: Props) => {
  const {whiteColorLinkClassName, topBarHeight} = useContext(LayoutContext);
  const classes = useStyles(topBarHeight)();
  const {t} = useTranslation();
  const theme = useTheme();
  const mobileHoneycomb = useMediaQuery(theme.breakpoints.down(665));
  const [modalTextId, setModalTextId] = useState<number>();

  const onCloseModal = useCallback(() => {
    setModalTextId(undefined);
  }, [setModalTextId])

  return (
    <Element
      name={AreasAtuacaoHashName}
      className={[
        classes.root,
        className || ''
      ].join(' ')}
    >
      <Container maxWidth="md">
        <div className={classes.content}>
          <Typography component="h2" color="primary" className={classes.title}>
            {t(`Home.AreasAtuacao.title`)}
          </Typography>
          <div className={classes.honeycombContent}>
            <div className={[classes.honeycombItem, classes.honeycombTextContent, classes.honeycombItem1].join(' ')}>
              <Honeycomb rotated={mobileHoneycomb} className={classes.honeycomb} imageUrl="assets/images/areas-atuacao-1.jpg" />
            </div>
            <div className={[classes.honeycombItem, classes.honeycombTextContent, classes.honeycombItem2].join(' ')}>
              <Honeycomb rotated={mobileHoneycomb} className={classes.honeycomb} imageUrl="assets/images/areas-atuacao-2.jpg" />
            </div>
            <div className={[classes.honeycombItem, classes.honeycombTextContent, classes.honeycombItem3].join(' ')}>
              <Honeycomb rotated={mobileHoneycomb} className={classes.honeycomb} imageUrl="assets/images/areas-atuacao-3.jpg" />
            </div>
            <div className={[classes.honeycombItem, classes.honeycombTextContent, classes.honeycombItem4].join(' ')}>
              <Honeycomb rotated={mobileHoneycomb} className={classes.honeycomb} color="primary" />
              <Typography component="p" className={classes.honeycombText} dangerouslySetInnerHTML={{__html: t(`Home.AreasAtuacao.text1`)}} />
              <button
                className={[
                  classes.honeycombButton,
                  whiteColorLinkClassName,
                ].join(' ')}
                onClick={() => setModalTextId(1)}
              >
                {t(`Home.AreasAtuacao.aboutMore`)}
              </button>
            </div>
            <div className={[classes.honeycombItem, classes.honeycombTextContent, classes.honeycombItem5].join(' ')}>
              <Honeycomb rotated={mobileHoneycomb} className={classes.honeycomb} imageUrl="assets/images/areas-atuacao-4.jpg" />
            </div>
            <div className={[classes.honeycombItem, classes.honeycombTextContent, classes.honeycombItem6].join(' ')}>
              <Honeycomb rotated={mobileHoneycomb} className={classes.honeycomb} color="primary" />
              <Typography component="p" className={classes.honeycombText} dangerouslySetInnerHTML={{__html: t(`Home.AreasAtuacao.text2`)}} />
              <button
                className={[
                  classes.honeycombButton,
                  whiteColorLinkClassName,
                ].join(' ')}
                onClick={() => setModalTextId(2)}
              >
                {t(`Home.AreasAtuacao.aboutMore`)}
              </button>
            </div>
            <div className={[classes.honeycombItem, classes.honeycombTextContent, classes.honeycombItem7].join(' ')}>
              <Honeycomb rotated={mobileHoneycomb} className={classes.honeycomb} color="primary" />
              <Typography component="p" className={classes.honeycombText} dangerouslySetInnerHTML={{__html: t(`Home.AreasAtuacao.text3`)}} />
              <button
                className={[
                  classes.honeycombButton,
                  whiteColorLinkClassName,
                ].join(' ')}
                onClick={() => setModalTextId(3)}
              >
                {t(`Home.AreasAtuacao.aboutMore`)}
              </button>
            </div>
            <div className={[classes.honeycombItem, classes.honeycombTextContent, classes.honeycombItem8].join(' ')}>
              <Honeycomb rotated={mobileHoneycomb} className={classes.honeycomb} imageUrl="assets/images/areas-atuacao-5.jpg" />
            </div>
            <div className={[classes.honeycombItem, classes.honeycombTextContent, classes.honeycombItem9].join(' ')}>
              <Honeycomb rotated={mobileHoneycomb} className={classes.honeycomb} imageUrl="assets/images/areas-atuacao-6.jpg" />
            </div>
            <div className={[classes.honeycombItem, classes.honeycombTextContent, classes.honeycombItem10].join(' ')}>
              <Honeycomb rotated={mobileHoneycomb} className={classes.honeycomb} imageUrl="assets/images/areas-atuacao-7.jpg" />
            </div>
          </div>
        </div>
      </Container>
      <AreasAtuacaoModal modalTextId={modalTextId} onClose={onCloseModal} />
    </Element>
  )
}

export default AreasAtuacao;