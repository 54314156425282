import { Button, Container, createStyles, IconButton, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import C from 'components/C';
import CircleBottomArrowIcon from 'components/Icons/CircleBottomArrowIcon';
import LayoutContext from 'components/Layout/LayoutContext';
import Links from 'enuns/Links';
import { primaryColor, secondaryColor, whiteColor } from 'library/Colors';
import { CarreirasHashName } from 'library/Routes';
import React, { Fragment, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import Slider from 'react-slick';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: whiteColor,
        width: '100%',
      },
      firstRow: {
        width: '100%',
        overflow: 'hidden',
      },
      divisor: {
        width: '100%',
        height: '20px',
        backgroundColor: primaryColor,
      },
      secondRow: {
        width: '100%',
        backgroundColor: primaryColor,
        padding: theme.spacing(8, 0),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(4, 0, 6, 0),
        }
      },
      contents: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      },
      leftContent: {
        paddingTop: `${theme.spacing(10)}px !important`,
        paddingBottom: `${theme.spacing(10)}px !important`,
        width: `${(100 / 12) * 5}%`,
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
          width: `${(100 / 12) * 7}%`,
          paddingTop: `${theme.spacing(7)}px !important`,
          paddingBottom: `${theme.spacing(7)}px !important`,
          paddingRight: `${theme.spacing(2)}px`
        },
        [theme.breakpoints.down('xs')]: {
          width: `62%`,
        }
      },
      rightContent: {
        position: 'relative',
        width: `${(100 / 12) * 7}%`,
        [theme.breakpoints.down('sm')]: {
          width: `${(100 / 12) * 5}%`,
        },
        [theme.breakpoints.down('xs')]: {
          width: `38%`,
        }
      },
      title: {
        fontSize: `${theme.spacing(3)}px`,
        fontWeight: 700,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
        }
      },
      text: {
        fontSize: `16px`,
        textAlign: 'left',
        marginTop: theme.spacing(3),
        maxWidth: '320px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        }
      },
      button: {
        marginTop: theme.spacing(4),
      },
      pics: {
        position: 'absolute',
        left: '25%',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'grid',
        gridTemplateColumns: '135px 135px 135px 135px 135px',
        gridTemplateRows: '135px 135px 135px ',
        [theme.breakpoints.down('xs')]: {
          gridTemplateColumns: '92px 92px 92px 92px 92px',
          gridTemplateRows: '92px 92px 92px 92px 92px 92px',
        },
      },
      pic: {
        width: `123px`,
        height: `123px`,
        [theme.breakpoints.down('xs')]: {
          width: `81px`,
          height: `81px`,
        },
      },
      c: {
        position: 'absolute',
        bottom: theme.spacing(23),
        right: theme.spacing(2),
        fontSize: '100px',
        [theme.breakpoints.down('sm')]: {
          fontSize: `58px`,
          bottom: theme.spacing(5),
          right: theme.spacing(5),
        }
      },
      carousel: {
        width: '100%',
        marginLeft: '0',
        '& .slick-arrow': {
          width: '50px'
        },
        '& .slick-dots': {
          bottom: '-35px',
          '& > li': {
            position: 'relative',
            margin: theme.spacing(0, 0.2),
            '& > button': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: 0,
              width: theme.spacing(1),
              height: theme.spacing(1),
              backgroundColor: whiteColor,
              borderRadius: theme.spacing(0.5),
              '&:before': {
                content: 'none',
              }
            },
            '&.slick-active > button': {
              backgroundColor: secondaryColor,
            }
          }
        }
      },
      carouselWithArrows: {
        width: 'calc(100% - 120px)',
        marginLeft: '60px',
      },
      carouselItem: {
        display: 'flex !important',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'center',
        }
      },
      carouselItemPhotoContent: {
        maxWidth: '182px',
        maxHeight: '182px',
        '& > *': {
          width: '100%',
          height: '100%',
        }
      },
      carouselItemTextContent: {
        flex: 1,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 0,
          paddingRight: 0,
          textAlign: 'center',
        }
      },
      carouselItemTitle: {
        color: whiteColor,
        fontSize: '16px',
        fontWeight: 700,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(3)
        }
      },
      carouselItemSubtitle: {
        color: whiteColor,
        fontSize: '16px',
      },
      carouselItemText: {
        marginTop: theme.spacing(2),
        color: whiteColor,
        fontSize: '14px',
      },
      carouselArrowButton: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        '&:before': {
          content: 'none !important'
        },
        color: whiteColor,
        '& svg': {
          fontSize: '34px',
        }
      },
      carouselPrevArrowButton: {
        left: '-72px',
      },
      carouselNextArrowButton: {
        right: '-72px',
      }
    }),
);

const PrevArrow = ({
  className,
  style,
  onClick,
}: any) => {
  const classes = useStyles();
  return (
    <Fragment>
      <IconButton onClick={onClick} className={[
        classes.carouselArrowButton,
        classes.carouselPrevArrowButton
      ].join(' ')}>
        <CircleBottomArrowIcon style={{transform: 'rotate(90deg)'}} />
      </IconButton>
    </Fragment>
  )
}

const NextArrow = ({
  className,
  style,
  onClick,
}: any) => {
  const classes = useStyles();
  return (
    <Fragment>
      <IconButton onClick={onClick} className={[
        classes.carouselArrowButton,
        classes.carouselNextArrowButton
      ].join(' ')}>
        <CircleBottomArrowIcon style={{transform: 'rotate(-90deg)'}} />
      </IconButton>
    </Fragment>
  )
}

const DEPOIMENTOS: string[] = [
  'salvador',
  'felipe',
]

interface Props {
  className?: string;
}

const Carreiras = ({className}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {t} = useTranslation();
  const {isMdSize, isSmSize} = useContext(LayoutContext);

  const showArrows = useMemo<boolean>(() => {
    if (isMdSize) {
      return DEPOIMENTOS.length > 2;
    }
    return DEPOIMENTOS.length > 1;
  }, [isMdSize])

  return (
    <Element
      name={CarreirasHashName}
      className={[
        classes.root,
        className || ''
      ].join(' ')}
    >
      <div className={classes.firstRow}>
        <Container maxWidth="md">
          <div
            className={classes.contents}
          >
            <div className={classes.leftContent}>
              <Typography component="h2" color="primary" className={classes.title}>
                {t(`Home.Carreiras.title`)}
              </Typography>
              <Typography component="p" color="secondary" className={classes.text} dangerouslySetInnerHTML={{__html: t(`Home.Carreiras.text`)}} />
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                component="a"
                href={Links.LinkedInJobs}
                rel="noreferrer"
                target="_blank"
              >
                {t(`Home.Carreiras.vagasButton`)}
              </Button>
            </div>
            <div className={classes.rightContent}>
              <div className={classes.pics}>
                {
                  Array.from({length: isSmSize ? 20 : 30}, (_, i) => i).map(
                    id => (
                      <div key={id}>
                        <img src={`assets/images/profissionais-${(id % 8) + 1}.jpg`} className={classes.pic} alt="" />
                      </div>
                    )
                  )
                }
              </div>
              <C className={classes.c} color="primary" />
            </div>
          </div>
        </Container>
      </div>
      {/* <div className={classes.divisor}>
      </div> */}
      <div className={classes.secondRow}>
        <Container maxWidth="md">
          {/* <Slider
            className={[
              classes.carousel,
              showArrows ? classes.carouselWithArrows : ''
            ].join(' ')}
            dots={false}
            infinite={true}
            speed={500}
            slidesToShow={2}
            slidesToScroll={1}
            prevArrow={showArrows ? <PrevArrow /> : <></>}
            nextArrow={showArrows ? <NextArrow /> : <></>}
            responsive={[
              {
                breakpoint: theme.breakpoints.values.md,
                settings: {
                  slidesToShow: 1,
                  dots: true,
                }
              },
            ]}
          >
            {
              DEPOIMENTOS.map(
                (it, key: number) => {
                  return (
                    <div key={key} className={classes.carouselItem}>
                      <div className={classes.carouselItemPhotoContent}>
                        <img src={`assets/images/depoimento-${it}.jpg`} alt={t(`Home.Carreiras.depoimentos.${it}.title`)} />
                      </div>
                      <div className={classes.carouselItemTextContent}>
                        <Typography component="h4" className={classes.carouselItemTitle}>
                          {t(`Home.Carreiras.depoimentos.${it}.title`)}
                        </Typography>
                        <Typography component="h4" className={classes.carouselItemSubtitle} dangerouslySetInnerHTML={{__html: t(`Home.Carreiras.depoimentos.${it}.subtitle`)}} />
                        <Typography component="p" className={classes.carouselItemText} dangerouslySetInnerHTML={{__html: t(`Home.Carreiras.depoimentos.${it}.text`)}} />
                      </div>
                    </div>
                  )
                }
              )
            }
          </Slider> */}
        </Container>
      </div>
    </Element>
  )
}

export default Carreiras;