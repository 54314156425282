import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { IconUIComponentProps, useIconStyle } from 'components/Icons/Icon';
import { primaryColor, whiteColor } from 'library/Colors';
import React from 'react';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: `${222 * 1 / 72}em`,
        height: '1em'
      },
    }),
);

const MiniBrand = ({
  color,
  size,
  style,
  className,
  noFill,
}: IconUIComponentProps) => {
  const classes = useStyles();
  const styles = useIconStyle({ noFill, size, color });
  
  return (
    <svg
      className={[styles.icon, className, classes.root].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 222 72"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M90.9399 62.58H61.48V71.6499H90.9399V62.58Z" fill={primaryColor} />
      <path d="M136.22 0.979957L111.54 1.06998V56.75H136.22C153.6 56.75 165.66 45.02 165.66 28.75C165.66 12.71 154.59 0.899957 136.22 0.979957ZM136.22 44.2799H124.99V13.4499H136.22C145.73 13.4499 152.05 18.7799 152.05 28.7799C152.05 38.1299 145.57 44.2799 136.22 44.2799Z" fill={primaryColor} />
      <path d="M13.45 0.97998H0V56.74H42.73V44.28H13.45V0.97998Z" fill={whiteColor} />
      <path d="M76.9097 12.14C83.9097 12.14 89.1298 15.5 91.7498 20.58L103.15 13.94C98.1497 5.58 88.7997 0 76.9097 0C59.4397 0 46.7397 13 46.7397 28.46C46.7397 44.94 59.4397 57.73 76.9097 57.73C82.1334 57.7935 87.2825 56.4873 91.8443 53.9415C96.4061 51.3957 100.221 47.6991 102.91 43.22L91.5097 36.66C90.0847 39.2709 87.9688 41.4394 85.3937 42.928C82.8185 44.4167 79.8834 45.1681 76.9097 45.1C74.723 45.1188 72.5546 44.7007 70.5317 43.8701C68.5088 43.0395 66.6722 41.8133 65.1296 40.2632C63.5871 38.7132 62.3696 36.8708 61.5488 34.8439C60.728 32.817 60.3204 30.6465 60.3497 28.46C60.3097 19.27 67.1097 12.14 76.9097 12.14Z" fill={whiteColor} />
      <path d="M210.44 39.94C216.44 36.74 220.36 29.94 220.36 21.94C220.36 9.8 211.84 1.01996 200.52 1.01996H173.52V56.79H186.95V13.49H198.6C200.854 13.5019 203.012 14.4089 204.597 16.0114C206.183 17.6139 207.067 19.7806 207.055 22.035C207.043 24.2893 206.136 26.4466 204.534 28.0322C202.931 29.6178 200.764 30.5019 198.51 30.49H191.01V42.96H196.83L205.19 56.82H221.91L210.44 39.94Z" fill={whiteColor} />
    </svg>
  );
}

export default MiniBrand;