import { Container, createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import Brand from 'components/Brand';
import C from 'components/C';
import CircleBottomArrowIcon from 'components/Icons/CircleBottomArrowIcon';
import LayoutContext from 'components/Layout/LayoutContext';
import PagesContext from 'components/Pages/PagesContext';
import { secondaryColor, whiteColor } from 'library/Colors';
import { HomeHashName } from 'library/Routes';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';

const useStyles = (topBarHeight: number, maxHeight: number) => makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        backgroundColor: secondaryColor,
        width: '100%',
        minHeight: `${maxHeight}px`,
        backgroundImage: `url(assets/images/lacador-bg.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        paddingTop: `${topBarHeight}px`,
        [theme.breakpoints.down(1004)]: {
          backgroundPosition: 'left top',
        },
        [theme.breakpoints.down(617)]: {
          backgroundRepeat: 'no-repeat',
          backgroundSize: '160%',
          backgroundPosition: '-30px 0',
        },
        '&:before': {
          content: '""',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: topBarHeight,
          backgroundImage: `url(assets/images/top-bar-linear-gradient.png)`,
          backgroundSize: '100% 97%',
          backgroundRepeat: 'no-repeat',
          zIndex: 1
        }
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        height: `${maxHeight - topBarHeight}px`,
      },
      middleContent: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        paddingRight: '192px',
        [theme.breakpoints.down(890)]: {
          paddingRight: `${162 * 100 / 890}vw !important`
        }
      },
      middle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      },
      bottomContent: {
        height: topBarHeight,
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingBottom: theme.spacing(2)
      },
      whaterMark: {
        position: 'absolute',
        top: `-95px`,
        right: `-192px`,
        fontSize: '321px',
        [theme.breakpoints.down(890)]: {
          top: `${(-95 * 100 / 890)}vw !important`,
          right: `${-162 * 100 / 890}vw !important`,
          fontSize: `${(321 * 100 / 890)}vw !important`,
        },
      },
      brand: {
        position: 'relative',
        zIndex: 1,
        fontSize: '558px !important',
        [theme.breakpoints.down(890)]: {
          fontSize: `${(558 * 100 / 890)}vw !important`,
        },
      },
      text: {
        position: 'relative',
        zIndex: 1,
        color: whiteColor,
        fontSize: `${theme.spacing(3)}px`,
        textAlign: 'right',
        marginTop: theme.spacing(3),
        [theme.breakpoints.down(890)]: {
          fontSize: `${(theme.spacing(3) * 100 / 890)}vw`,
        },
        [theme.breakpoints.down(577)]: {
          fontSize: `${theme.spacing(2)}px`,
        }
      },
      downButtonLabel: {
        color: whiteColor,
        fontSize: `14px`
      }
    }),
);

interface Props {
  className?: string;
}

const Lacador = ({className}: Props) => {
  const {topBarHeight, isSmSize, maxHeight} = useContext(LayoutContext);
  const classes = useStyles(topBarHeight, maxHeight)();
  const {t} = useTranslation();
  const { toQuemSomos } = useContext(PagesContext);

  return (
    <Element
      name={HomeHashName}
      className={[
        classes.root,
        className || ''
      ].join(' ')}
    >
      <Container maxWidth="md">
        <div className={classes.content}>
          <div className={classes.middleContent}>
            <div className={classes.middle}>
              <C color="#353535" className={classes.whaterMark} />
              <Brand className={classes.brand} />
              <span className={classes.text} dangerouslySetInnerHTML={{ __html: t(`Home.Lacador.text${!isSmSize ? 'Mobile' : ''}`) }} />
            </div>
          </div>
          <div className={classes.bottomContent}>
            <IconButton onClick={ toQuemSomos }>
              <CircleBottomArrowIcon color="primary" />
            </IconButton>
            <Typography component="p" className={classes.downButtonLabel}>
              {t(`Home.Lacador.downButtonLabel`)}
            </Typography>
          </div>
        </div>
      </Container>
    </Element>
  )
}

export default Lacador;