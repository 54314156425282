import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { IconUIComponentProps, useIconStyle } from 'components/Icons/Icon';
import React from 'react';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: `1em`,
        height: `${147 * 1 / 116}em`
      },
    }),
);

const C = ({
  color,
  size,
  style,
  className,
  noFill,
}: IconUIComponentProps) => {
  const classes = useStyles();
  const styles = useIconStyle({ noFill, size, color });
  
  return (
    <svg
      className={[styles.icon, className, classes.root].join(' ').trim()}
      fontSize={size}
      style={style}
      viewBox="0 0 116 147"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M90.7378 128.356H30.3032V146.944H90.7378V128.356Z" />
      <path d="M61.8864 24.894C76.3474 24.894 86.9512 31.7829 92.3314 42.2159L115.703 28.5947C105.44 11.4293 86.268 0 61.8864 0C26.0611 0 0 26.5736 0 58.3565C0 92.1606 26.0611 118.393 61.8864 118.393C72.6273 118.549 83.2211 115.883 92.608 110.66C101.995 105.437 109.846 97.8404 115.375 88.6308L91.9898 75.1803C86.4388 85.2717 76.02 92.5022 61.8864 92.5022C57.3989 92.5386 52.9495 91.6786 48.7989 89.9724C44.6483 88.2663 40.8802 85.7485 37.7156 82.5667C34.5511 79.3849 32.0537 75.6032 30.3702 71.4434C28.6866 67.2835 27.8507 62.8294 27.9115 58.3423C27.9115 39.5259 41.8743 24.894 61.8864 24.894Z" />
    </svg>
  );
}

export default C;