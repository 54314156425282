import { createStyles, makeStyles, Tooltip } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import React, { useCallback, useMemo, useState } from 'react';

const useStyles = makeStyles(
  () =>
    createStyles({
      hidden: {
        opacity: '0',
      },
    }),
  { name: 'CustomTooltip' },
);

interface Props {
  title?: string;
  className?: string;
  classNameContent?: string;
  children?: any;
}

const CustomTooltip = ({ title, className, classNameContent, children }: Props) => {
  const classes = useStyles();
  const [opened, setOpened] = useState<boolean>(false);

  const isShown = useMemo<boolean>(() => {
    return typeof title === 'string' && title.trim().length > 0;
  }, [title]);

  const handleTooltipClose = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const handleTooltipOpen = useCallback(() => {
    if (isShown) {
      setOpened(true);
    }
  }, [isShown, setOpened]);

  const tooltipElement = useCallback(() => {
    return (
      <ClickAwayListener onClickAway={() => handleTooltipClose()}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => handleTooltipClose()}
          onOpen={() => handleTooltipOpen()}
          open={opened}
          disableFocusListener
          disableTouchListener
          className={[className || '', isShown ? '' : classes.hidden].join(' ')}
          title={title || ''}
        >
          <span className={[classNameContent || ''].join(' ')} onClick={() => handleTooltipOpen()}>
            {children}
          </span>
        </Tooltip>
      </ClickAwayListener>
    );
  }, [handleTooltipClose, opened, handleTooltipOpen, className, classes, classNameContent, isShown, title, children]);

  return tooltipElement();
};

export default CustomTooltip;