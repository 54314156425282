import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import CustomTooltip from 'components/CustomTooltip';
import InstagramIcon from 'components/Icons/InstagramIcon';
import LinkedInIcon from 'components/Icons/LinkedInIcon';
import MapIcon from 'components/Icons/MapIcon';
import MessageIcon from 'components/Icons/MessageIcon';
import LayoutContext from 'components/Layout/LayoutContext';
import Links from 'enuns/Links';
import { primaryColor, secondaryColor, whiteColor } from 'library/Colors';
import { ContatoHashName } from 'library/Routes';
import React, { Fragment, ReactNode, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        backgroundColor: whiteColor,
        width: '100%',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      map: {
        position: 'absolute',
        width: '50%',
        height: '100%',
        top: 0,
        left: 0,
        '& > iframe': {
          border: 0,
        }
      },
      mobileMap: {
        height: '400px',
        '& > iframe': {
          border: 0,
          marginLeft: theme.spacing(-3),
          width: `calc(100% + ${theme.spacing(6)}px)`,
          height: '100%',
        }
      },
      rightContent: {
        padding: theme.spacing(7),
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 0),
        },
      },
      title: {
        fontSize: `${theme.spacing(3)}px`,
        fontWeight: 700,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
          fontSize: `18px`,
        },
      },
      items: {
        paddingLeft: 0,
      },
      item: {
        listStyleType: 'none',
        display: 'flex',
        padding: theme.spacing(2, 0),
      },
      icon: {
        backgroundColor: whiteColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontSize: '16px'
      },
      labelItem: {
        color: secondaryColor,
        fontSize: '16px',
        paddingLeft: theme.spacing(2),
        lineHeight: '19px',
        marginTop: '-3px',
      },
      labelTitleItem: {
        display: 'block',
        fontWeight: 600,
      },
      socialNetworkTitle: {
        fontSize: '16px',
        fontWeight: 700,
        color: secondaryColor,
      },
      socialNetworkItems: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
          justifyContent: 'center',
        }
      },
      socialNetworkItem: {
        minWidth: 'initial',
        padding: theme.spacing(0.5),
        [theme.breakpoints.up('md')]: {
          '&:first-child': {
            marginLeft: theme.spacing(-0.5),
          },
        },
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(0, 1)
        },
      },
      socialNetworkMobile: {
        textAlign: 'center',
        backgroundColor: primaryColor,
        padding: theme.spacing(3),
        '& > *': {
          color: `${whiteColor} !important`,
        },
        '& svg': {
          fill: `${whiteColor} !important`,
        },
      }
    }),
);

interface Props {
  className?: string;
}

const Contato = ({className}: Props) => {
  const classes = useStyles();
  const {secondaryColorLinkClassName, isSmSize} = useContext(LayoutContext);
  const {t} = useTranslation();

  const map = useMemo<ReactNode>(() => {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3455.4087762739805!2d-51.203537285304364!3d-29.996416736003926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9519798b1671aa71%3A0x2a0b0b9e122443ee!2sTv.%20S%C3%A3o%20Jos%C3%A9%2C%20445%20-%20Navegantes%2C%20Porto%20Alegre%20-%20RS%2C%2090240-200!5e0!3m2!1spt-BR!2sbr!4v1627655366490!5m2!1spt-BR!2sbr"
        title="ddd"
        width="100%"
        height="100%"
        loading="lazy"
      >
      </iframe>
    )
  }, [])

  return (
    <Fragment>
      <Element
        name={ContatoHashName}
        className={[
          classes.root,
          className || ''
        ].join(' ')}
      >
        {
          isSmSize && (
            <div className={classes.map}>
              {map}
            </div>
          )
        }
        <Container maxWidth="md">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6}>
              {
                !isSmSize && (
                  <div className={classes.mobileMap}>
                    {map}
                  </div>
                )
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.rightContent}>
                <Typography component="h2" color="primary" className={classes.title}>
                  {t(`Home.Contato.title`)}
                </Typography>
                <ul className={classes.items}>
                  {
                    (t(`Home.Contato.items`, {returnObjects: true}) as any[]).map(
                      (it: any, key: number) => {
                        if (key === 1) return <Fragment key={key}></Fragment>;
                        return (
                          <li className={classes.item} key={key}>
                            <div className={classes.icon}>
                              { key === 0 && <MessageIcon color="primary" /> }
                              {/* { key === 1 && <WhatsAppIcon color="primary" /> } */}
                              { key === 2 && <MapIcon color="primary" /> }
                            </div>
                            <Typography component="p" className={classes.labelItem}>
                              <Typography component="span" className={classes.labelTitleItem}>
                                {it.label}
                              </Typography>
                              {
                                key === 0 ? (
                                  <a
                                    href={`mailto:${it.text}`}
                                    className={secondaryColorLinkClassName}
                                  >
                                    {it.text}
                                  </a>
                                ) : (
                                  <span dangerouslySetInnerHTML={{__html: it.text}} />
                                )
                              }
                            </Typography>
                          </li>
                        )
                      }
                    )
                  }
                </ul>
                {
                  isSmSize && (
                    <Fragment>
                      <Typography component="h4" className={classes.socialNetworkTitle}>
                        {t(`Home.Contato.socialNetworkTitle`)}
                      </Typography>
                      <div className={classes.socialNetworkItems}>
                        <CustomTooltip title={t(`Home.Contato.commingSoon`).toString()} classNameContent={classes.socialNetworkItem}>
                          <span>
                            <InstagramIcon color="primary" />
                          </span>
                        </CustomTooltip>
                        <a className={classes.socialNetworkItem} href={Links.LinkedIn} rel="noreferrer" target="_blank">
                          <LinkedInIcon color="primary" />
                        </a>
                      </div>
                    </Fragment>
                  )
                }
              </div>
            </Grid>
          </Grid>
        </Container>
      </Element>
      {
        !isSmSize && (
          <div className={classes.socialNetworkMobile}>
            <Typography component="h4" className={classes.socialNetworkTitle}>
              {t(`Home.Contato.socialNetworkTitle`)}
            </Typography>
            <div className={classes.socialNetworkItems}>
              <CustomTooltip title={t(`Home.Contato.commingSoon`).toString()} classNameContent={classes.socialNetworkItem}>
                <span>
                  <InstagramIcon color="primary" />
                </span>
              </CustomTooltip>
              <a className={classes.socialNetworkItem} href={Links.LinkedIn} rel="noreferrer" target="_blank">
                <LinkedInIcon color="primary" />
              </a>
            </div>
          </div>
        )
      }
    </Fragment>
  )
}

export default Contato;