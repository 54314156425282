import LayoutContext from "components/Layout/LayoutContext";
import { AreasAtuacaoHashName, AreasAtuacaoRoutePath, CarreirasHashName, CarreirasRoutePath, ContatoHashName, ContatoRoutePath, HomeRoutePath, QuemSomosHashName, QuemSomosRoutePath } from "library/Routes";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router";
import { animateScroll, scroller } from 'react-scroll';
import { IPagesContext } from "../../interfaces/IPagesContext";
import PagesContext from './PagesContext';

interface Props {
  children: any;
}

const shortDuration: number = 500;

const Pages = ({
  children
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [currPath, setCurrPath] = useState<string>(window.location.pathname);
  const [currHash, setCurrHash] = useState<string>(window.location.hash.split('#').join(''));
  const {topBarHeight} = useContext(LayoutContext);
  const [initialized, setInitialized] = useState<boolean>();

  const scrollToOptions = useMemo<any>(() => {
    return {
      duration: shortDuration,
      delay: 200,
      offset: topBarHeight * -1,
      smooth: true,
      ignoreCancelEvents: false
    }
  }, [topBarHeight])
 
  const inHome = useMemo<boolean>(() => {
    return !!(currPath && matchPath(currPath, {
      path: HomeRoutePath,
      exact: true
    }));
  }, [currPath])
 
  const inQuemSomos = useMemo<boolean>(() => {
    return inHome && currHash === QuemSomosHashName;
  }, [inHome, currHash])

  const inAreasAtuacao = useMemo<boolean>(() => {
    return inHome && currHash === AreasAtuacaoHashName;
  }, [inHome, currHash])

  const inCarreiras = useMemo<boolean>(() => {
    return inHome && currHash === CarreirasHashName;
  }, [inHome, currHash])

  const inContato = useMemo<boolean>(() => {
    return inHome && currHash === ContatoHashName;
  }, [inHome, currHash])

  const toHome = useCallback(() => {
    new Promise((resolve) => {
      history.push(HomeRoutePath);
      resolve(undefined);
    }).then(() => animateScroll.scrollToTop({ duration: inHome ? shortDuration : 0 }));
  }, [history, inHome])

  const toInternalHomeSession = useCallback((sessionHashName: string, sessionPath: string, inSession: boolean) => {
    if (inSession) {
      scroller.scrollTo(sessionHashName, scrollToOptions)
    } else {
      new Promise((resolve) => {
        history.push(sessionPath);
        resolve(undefined);
      }).then(() => scroller.scrollTo(sessionHashName, scrollToOptions));
    }
  }, [history, scrollToOptions]);

  const toQuemSomos = useCallback(() => {
    toInternalHomeSession(QuemSomosHashName, QuemSomosRoutePath, inQuemSomos);
  }, [toInternalHomeSession, inQuemSomos])

  const toAreasAtuacao = useCallback(() => {
    toInternalHomeSession(AreasAtuacaoHashName, AreasAtuacaoRoutePath, inAreasAtuacao);
  }, [toInternalHomeSession, inAreasAtuacao])

  const toCarreiras = useCallback(() => {
    toInternalHomeSession(CarreirasHashName, CarreirasRoutePath, inCarreiras);
  }, [toInternalHomeSession, inCarreiras])

  const toContato = useCallback(() => {
    toInternalHomeSession(ContatoHashName, ContatoRoutePath, inContato);
  }, [toInternalHomeSession, inContato])

  useEffect(() => {
    setCurrPath(location.pathname);
    setCurrHash(location.hash.split('#').join(''));
  }, [location]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      if (inQuemSomos) {
        window.setTimeout(toQuemSomos, 500);
      }
    }
  }, [initialized, setInitialized, inQuemSomos, toQuemSomos])
  
  const contextValue = useMemo<IPagesContext>(() => {
    return {
      inHome,
      inQuemSomos,
      inAreasAtuacao,
      inCarreiras,
      inContato,
      toHome,
      toQuemSomos,
      toAreasAtuacao,
      toCarreiras,
      toContato,
    }
  }, [
    inHome,
    inQuemSomos,
    inAreasAtuacao,
    inCarreiras,
    inContato,
    toHome,
    toQuemSomos,
    toAreasAtuacao,
    toCarreiras,
    toContato,
  ])

  return (
    <PagesContext.Provider value={contextValue}>
      {children}
    </PagesContext.Provider>
  )
}

export default Pages;