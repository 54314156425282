import { alpha, createTheme, darken, lighten } from '@material-ui/core/styles';
import { primaryColor as primary, secondaryColor as secondary, whiteColor } from 'library/Colors';

const primaryColor = {
  50: lighten(primary, 1),
  100: lighten(primary, 0.8),
  200: lighten(primary, 0.6),
  300: lighten(primary, 0.4),
  400: lighten(primary, 0.2),
  500: primary,
  600: darken(primary, 0.2),
  700: darken(primary, 0.4),
  800: darken(primary, 0.6),
  900: darken(primary, 0.8),
  A100: lighten(primary, 0.8),
  A200: lighten(primary, 0.6),
  A400: lighten(primary, 0.2),
  A700: darken(primary, 0.4),
  'contrastDefaultColor': whiteColor
};
const secondaryColor = {
  50: lighten(secondary, 1),
  100: lighten(secondary, 0.8),
  200: lighten(secondary, 0.6),
  300: lighten(secondary, 0.4),
  400: lighten(secondary, 0.2),
  500: secondary,
  600: darken(secondary, 0.2),
  700: darken(secondary, 0.4),
  800: darken(secondary, 0.6),
  900: darken(secondary, 0.8),
  A100: lighten(secondary, 0.8),
  A200: lighten(secondary, 0.6),
  A400: lighten(secondary, 0.2),
  A700: darken(secondary, 0.4),
  'contrastDefaultColor': whiteColor,
};

export const LayoutMuiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1120,
      lg: 1280,
      xl: 1920,
    }
  },
  typography: {
    fontFamily: '"Lato", sans-serif',
  },
  palette: {
    primary: primaryColor,
    secondary: secondaryColor,
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        borderRadius: '17px',
        fontSize: '14px',
        lineHeight: '18px',
        padding: '8px 16px',
        textTransform: 'none',
        boxShadow: 'none !important',
      },
      textSecondary: {
        color: `${whiteColor} !important`
      }
    },
    MuiContainer: {
      root: {
        paddingLeft: '24px',
        paddingRight: '24px',
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: `${primary}`,
        boxShadow: `5px 1px 10px ${alpha(secondary, 0.5)}`
      }
    }
  }
});